import React from "react";
import styles from "./StaffEvent.module.css";
import EventItem from "../../common/eventItem/EventItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StaffEvent = ({ data }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.content}>
      <h1>{t("staffEventTitle")}</h1>
      <div className={styles.buttons_content}>
        {/* Action buttons */}
        <div className={styles.buttons_group}>
          <button
            class="solid_button"
            onClick={() => navigate("/home/codeScanner")}
          >
            {t("scanInvitation")}
          </button>
        </div>
      </div>

      <div className={styles.border_content}>
        <EventItem item={data} />
      </div>
    </div>
  );
};

export default StaffEvent;
