import styles from "./Invitations.module.css";
import { useTranslation } from "react-i18next";
import InvitationItem from "./../../common/InvitationItem/InvitationItem";
import { useState, useEffect, useReducer } from "react";
import { httpsCallable } from "firebase/functions";
import { functions, storage } from "./../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from "@vis.gl/react-google-maps";
import Searching from "../../common/searching/Searching";

const Invitations = ({ user, setEventData }) => {
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [list, setList] = useState([]);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [owner, setOwner] = useState(null);
  const [picture, setPicture] = useState("");
  const [loading, setLoading] = useState(false);
  const [opening, setOpening] = useState(false);
  const [selected, setSelected] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [data, setData] = useState({
    information: { date: "", time: "" },
    description: { name: "", description: "" },
    location: { name: "", address: "" },
  });

  useEffect(() => {
    fetchInvitations();
  }, []);

  const handleClose = () => {
    setSelected(null);
    setLoading(false);
    setFetching(false);
    setOpening(false);
    setOpen(false);
  };

  const openInvitation = async (item) => {
    setSelected(item);
    setOpening(true);
    try {
      await fetchEventInfo(item.eventId);
      await getImageUrl(item.eventId);
      await fetchOwnerInfo(item.ownerId);
      await getPictureUrl(item.ownerId);
      setOpen(true);
    } catch (error) {
      setSelected(null);
      setOpening(false);
    }
  };

  const handleChoice = async (accept) => {
    setLoading(true);
    const requestData = {
      id: selected.id,
      status: accept ? "accepted" : "denied",
    };
    const updateStatus = httpsCallable(functions, "updateInvitationStatus");
    try {
      await updateStatus(requestData);
      fetchInvitations();
      setOpen(false);
      setOpening(false);
    } catch (error) {
      setLoading(false);
      alert(t("generalError"));
    }
  };

  const getImageUrl = async (eventId) => {
    const path = "event/" + eventId + "/event_image/event_image.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setUrl(url);
    } catch (error) {
      setUrl(null);
    }
  };

  const getPictureUrl = async (ownerId) => {
    const path = "user/" + ownerId + "/profile_photo/profile_photo.jpg";
    // Create path reference
    const reference = ref(storage, path);
    // Get download URL
    try {
      const url = await getDownloadURL(reference);
      setPicture(url);
    } catch (error) {
      setPicture(null);
    }
  };

  const fetchStaffEvent = async (item) => {
    const dataCall = { id: item.eventId };
    const searchEvent = httpsCallable(functions, "searchEventById");
    try {
      const result = await searchEvent(dataCall);
      const data = mapItem(result.data.data);
      // Staff mode active when event is
      if (data.status === "begin") {
        setEventData(data);
        navigate("/home/staffEvent");
      } else {
        alert(t("staffActiveEventError"));
      }
    } catch (error) {
      alert(t("generalError"));
    }
  };

  const fetchEventInfo = async (eventId) => {
    const requestData = { id: eventId };
    const downloadInfo = httpsCallable(functions, "searchEventById");
    try {
      const result = await downloadInfo(requestData);
      const data = mapItem(result.data.data);
      setData(data);
    } catch (error) {
      alert(t("generalError"));
    }
  };

  const fetchOwnerInfo = async (ownerId) => {
    const requestData = { id: ownerId };
    const downloadInfo = httpsCallable(functions, "downloadProfile");
    try {
      const result = await downloadInfo(requestData);
      const jsonString = JSON.stringify(result.data);
      const json = JSON.parse(jsonString);
      setOwner({
        id: json.id,
        name: json.profile.name,
        lastName: json.profile.last_name,
      });
    } catch (error) {
      alert(t("generalError"));
    }
  };

  const mapItem = (item) => {
    return {
      id: item.id,
      ownerId: item.owner_id,
      transactionId: item.transaction_id,
      status: item.status,
      location: {
        name: item.place_name,
        placeID: item.place_id,
        address: item.address,
        coordinate: { lat: item.latitude, lng: item.longitude },
      },
      information: {
        type: item.type,
        date: item.date,
        time: item.time,
        totalInvites: item.total_invites,
      },
      description: {
        name: item.name,
        description: item.description,
        cost: item.cost,
      },
    };
  };

  const fetchInvitations = async () => {
    setFetching(true);
    const requestData = { id: user.id };
    const downloadList = httpsCallable(functions, "searchProfileInvitations");
    try {
      const result = await downloadList(requestData);
      const response = result.data.data;
      const jsonString = JSON.stringify(response);
      const json = JSON.parse(jsonString);
      const list = Object.keys(json).map((key) => ({
        id: key,
        eventId: json[key].event_id,
        guestId: json[key].guest_id,
        ownerId: json[key].owner_id,
        status: json[key].status,
        type: json[key].type,
      }));
      const filter = list.filter((invitation) => invitation.status != "denied");
      setList(filter);
      forceUpdate();
      setFetching(false);
    } catch (error) {
      setList([]);
      setFetching(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2>{t("invitationsTitle")}</h2>
      <div className={styles.list}>
        {fetching && <Searching />}
        {!fetching && list.length === 0 && (
          <div className={styles.empty_content}>
            <h1>{t("emptyList")}</h1>
            <p>{t("emptyInvitationsDescription")}</p>
            <img src="../../img/mountain.png" />
          </div>
        )}
        {!fetching &&
          list.length > 0 &&
          list.map((item) => (
            <div>
              <InvitationItem item={item} />
              {opening && <p>{t("loading")}</p>}
              {!opening && (
                <>
                  {item.status === "send" && (
                    <button
                      class="solid_button"
                      onClick={() => {
                        openInvitation(item);
                      }}
                    >
                      {t("openInvitation")}
                    </button>
                  )}
                  {item.type === "staff" && item.status === "accepted" && (
                    <button
                      class="solid_button"
                      onClick={() => {
                        fetchStaffEvent(item);
                      }}
                    >
                      {t("staff")}
                    </button>
                  )}
                </>
              )}
            </div>
          ))}
      </div>

      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <div className={styles.modal}>
              {/* background */}
              {url === null && <img src="../../img/background.png" />}
              {url !== null && <img src={url} alt="Imagen" />}

              <div className={styles.modal_content}>
                {/* event info */}
                <div className={styles.modal_info}>
                  <h1>{data.description.name}</h1>
                  <h2>
                    {t("date")} :{" "}
                    {format(new Date(data.information.date), "dd MMM yyyy")}
                  </h2>
                  <h2>
                    {t("time")} :{" "}
                    {format(new Date(data.information.time), "HH:mm")}
                  </h2>
                  <p>{data.description.description}</p>
                </div>

                {/* event location */}
                <div className={styles.modal_location}>
                  <h2>{t("modalLocationTitle")}</h2>
                  <div className={styles.modal_map}>
                    <APIProvider
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      libraries={["places"]}
                    >
                      <Map
                        mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                        zoom={14}
                        center={{
                          lat: parseFloat(data.location.coordinate.lat),
                          lng: parseFloat(data.location.coordinate.lng),
                        }}
                      >
                        <AdvancedMarker
                          position={{
                            lat: parseFloat(data.location.coordinate.lat),
                            lng: parseFloat(data.location.coordinate.lng),
                          }}
                        >
                          {/* add color values on config file */}
                          <Pin
                            background={process.env.REACT_APP_PIN_BACKGROUND}
                            glyphColor={process.env.REACT_APP_PIN_GLYPH_COLOR}
                            borderColor={process.env.REACT_APP_PIN_BORDER_COLOR}
                          />
                        </AdvancedMarker>
                      </Map>
                    </APIProvider>
                  </div>
                  <h2>{data.location.name}</h2>
                  <p>{data.location.address}</p>
                </div>

                {/* creator info */}
                <div className={styles.modal_profile_info}>
                  <h3>{t("eventCreator")}</h3>
                  {picture === null && <img src="../../img/account_icon.png" />}
                  {picture !== null && <img src={picture} />}
                  <h2>
                    {owner.name} {owner.lastName}
                  </h2>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            {loading && (
              <>
                <label>{t("loading")}</label>
              </>
            )}
            {!loading && (
              <>
                <Button onClick={() => handleChoice(false)}>
                  {t("declineInvitation")}
                </Button>
                <Button onClick={() => handleChoice(true)}>
                  {t("acceptInvitation")}
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Invitations;
