import "./App.css";
import "./i18n.js";
import CryptoJS from "crypto-js";
import { useState, useEffect } from "react";
import Home from "./components/home/Home.jsx";
import Main from "./components/index/main/Main.jsx";
import Terms from "./components/index/legal/Terms.jsx";
import Policy from "./components/index/legal/Policy.jsx";
import SignIn from "./components/index/signIn/SignIn.jsx";
import SignUp from "./components/index/signUp/SignUp.jsx";
import Profile from "./components/home/profile/Profile.jsx";
import Payment from "./components/home/payment/Payment.jsx";
import MapFilter from "./components/home/map/MapFilter.jsx";
import MyEvents from "./components/home/myEvents/MyEvents.jsx";
import IndexError from "./components/index/error/IndexError.jsx";
import EditEvent from "./components/home/editEvent/EditEvent.jsx";
import CodeScanner from "./components/home/scanner/CodeScanner.jsx";
import StaffEvent from "./components/home/staffEvent/StaffEvent.jsx";
import SearchGuest from "./components/home/searchGuest/SearchGuest.jsx";
import Invitations from "./components/home/invitations/Invitations.jsx";
import ActiveEvent from "./components/home/activeEvent/ActiveEvent.jsx";
import PaymentSuccess from "./components/home/payment/PaymentSuccess.jsx";
import EventLocation from "./components/home/createEvent/location/EventLocation.jsx";
import EventInformation from "./components/home/createEvent/information/EventInformation.jsx";
import EventDescription from "./components/home/createEvent/description/EventDescription.jsx";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

const App = () => {
  const [user, setUser] = useState(() => {
    const encryptedSession = sessionStorage.getItem("data");
    if (encryptedSession === null) return null;
    const sessionBytes = CryptoJS.AES.decrypt(
      encryptedSession,
      process.env.REACT_APP_ENCRYPTION_SECRET_KEY
    );
    const sessionString = sessionBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(sessionString);
  });
  const [eventData, setEventData] = useState(null);

  useEffect(() => {}, [user]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<IndexError />}>
        <Route index element={<Main />} />
        <Route path="signIn" element={<SignIn setUser={setUser} />} />
        <Route path="signUp" element={<SignUp setUser={setUser} />} />
        <Route path="terms" element={<Terms />} />
        <Route path="policy" element={<Policy />} />

        <Route path="home" element={<Home user={user} setUser={setUser} />}>
          <Route index element={<MapFilter user={user} />} />
          <Route
            path="myEvents"
            element={<MyEvents user={user} setEventData={setEventData} />}
          />
          <Route
            path="editEvent"
            element={<EditEvent item={eventData} user={user} />}
          />
          <Route
            path="invitations"
            element={<Invitations user={user} setEventData={setEventData} />}
          />
          <Route
            path="searchGuest"
            element={<SearchGuest data={eventData} />}
          />
          <Route
            path="activeEvent"
            element={<ActiveEvent data={eventData} />}
          />
          <Route path="staffEvent" element={<StaffEvent data={eventData} />} />
          <Route
            path="codeScanner"
            element={<CodeScanner data={eventData} />}
          />

          <Route path="payment" element={<Payment />} />

          <Route
            path="paymentSuccess"
            element={<PaymentSuccess setUser={setUser} />}
          />

          <Route
            path="profile"
            element={<Profile user={user} setUser={setUser} />}
          />

          <Route
            path="eventLocation"
            element={<EventLocation setEventData={setEventData} />}
          />

          <Route
            path="eventInfo"
            element={
              <EventInformation
                eventData={eventData}
                setEventData={setEventData}
              />
            }
          />

          <Route
            path="eventDescription"
            element={<EventDescription user={user} eventData={eventData} />}
          />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default App;
