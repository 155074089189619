"use client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./EventLocation.module.css";
import PlaceClick from "../../../common/PlaceClick";
import MapCameraUpdate from "../../../common/MapCameraUpdate";
import PlacesAutocomplete from "../../../common/Autocomplete/PlacesAutocomplete";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from "@vis.gl/react-google-maps";

const EventLocation = ({ setEventData }) => {
  const { t, i18n } = useTranslation();
  const [location, setLocation] = useState(null);
  const [userLocation, setUserLocation] = useState({
    loaded: false,
    coordinates: { lat: 0.0, lng: 0.0 },
  });
  const navigate = useNavigate();
  const [place, setPlace] = useState(null);
  const navigationPath = "/home/eventInfo";

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      alert("Geolocation no disponible");
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  const onSuccess = (location) => {
    setUserLocation({
      loaded: true,
      coordinates: {
        lat: parseFloat(location.coords.latitude),
        lng: parseFloat(location.coords.longitude),
      },
    });
  };

  const updateAddress = (props) => {
    setPlace({ name: props.name, address: props.fullAddress });
  };

  const onError = (error) => {
    setUserLocation({
      loaded: false,
      error,
    });
  };

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();
    setEventData({ location: location });
    navigate(navigationPath);
  };

  return (
    <div className={styles.content}>
      <h1>{t("modalLocationTitle")}</h1>
      {!userLocation.loaded && (
        <div className={styles.loader_wrapper}>
          <div className={styles.loader_content}>
            <h1>{t("mapLoadingMessage")}</h1>
            <h2>{t("locationPermissionsTitle")}</h2>
            <p>{t("mapLocationDescription")}</p>
            <img src="../../img/map.png" />
          </div>
        </div>
      )}
      {userLocation.loaded && (
        <div className={styles.location_content}>
          <div className={styles.border_content}>
            <h2>{t("seekerTitle")}</h2>
            {userLocation.loaded && (
              <PlacesAutocomplete
                setPlace={setPlace}
                setLocation={setLocation}
                updateAddress={updateAddress}
              />
            )}
            <p>{t("mapLocationHint")}</p>
          </div>

          <div className={styles.location_section}>
            <div className={styles.left_section}>
              <div className={styles.map_section}>
                <APIProvider
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  libraries={["places"]}
                >
                  <>
                    <Map
                      mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                      zoom={10} // City zoom
                      center={{
                        lat: userLocation.coordinates.lat,
                        lng: userLocation.coordinates.lng,
                      }}
                    >
                      {location && (
                        <AdvancedMarker
                          position={{
                            lat: location.latitude,
                            lng: location.longitude,
                          }}
                        >
                          <Pin
                            background={process.env.REACT_APP_PIN_BACKGROUND}
                            glyphColor={process.env.REACT_APP_PIN_GLYPH_COLOR}
                            borderColor={process.env.REACT_APP_PIN_BORDER_COLOR}
                          />
                        </AdvancedMarker>
                      )}
                    </Map>
                    <MapCameraUpdate location={location} />
                    <PlaceClick
                      setLocation={setLocation}
                      updateAddress={updateAddress}
                    />
                  </>
                </APIProvider>
              </div>
            </div>

            {location && (
              <div className={styles.right_section}>
                <label>{t("placeNameTitle")}</label>
                <p>{place.name}</p>
                <label>{t("addressTitle")}</label>
                <p>{place.address}</p>
                <button class="solid_button" onClick={handleSubmit}>
                  {t("next")}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventLocation;
