import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    lng: "es",
    resources: {
        es: {
            translation: {
                /* Common */
                appName: "Onix",
                emailFormatError: "El correo electronico debe tener un formato valido, verifica los datos e intenta nuevamente.",
                emptyEmail: "Correo vacío.",
                invalidCredentials: "Correo o contraseña incorrectos, verifica los datos e intenta nuevamente.",
                connectionError: "Ups! Algo esta mal con la conexión a internet",
                generalError: "Ups! Algo salió, Intenta despues nuevamente.",
                loading: "Espera un momento...",
                geolocationNotAvailable: "Geolocalización no disponible",
                minAgeErro: "La edad minima permitida son {{age}} años",
                noSuchPageTitle: "Página no encontrada",
                pageErrorDescription: "Parece que hay un error con la página a la que intentas acceder.",
                goIndex: "Volver al inicio",
                seeMore: "Ver más",
                search: "Buscar",
                image: "Imagen",
                error: "Error",
                send: "Enviar",
                accept: "Aceptar",
                cancel: "Cancelar",
                next: "Siguiente",
                date: "Fecha",
                time: "Hora",
                no: "No",
                /* Main */
                signIn: "Iniciar sesión",
                signUp: "Crear cuenta",
                header1: "Tu mejor opción para eventos digitales",
                description1: "Descubre Onix, diseñado para crear eventos de manera sostenible. Gestiona tus invitados, incluye personal y emite boletos e invitaciones digitales, contribuyendo así al cuidado del medio ambiente.",
                iosApp: "App gratuita para iOS",
                header2: "¡Adiós al papel!",
                description2: "¿Sigues usando papel para tus eventos? Con Onix, obtén un sistema móvil gratuito para boletos digitales, verificación de invitados y eliminación automática de invitaciones una vez finalizado el evento. ¡Únete al movimiento ecológico!",
                header3: "Controla tu personal",
                description3: "Invita y gestiona personal fácilmente en tus eventos con Onix. Cada miembro puede verificar invitaciones en tiempo real utilizando tecnología QR, reduciendo el uso de papel y agilizando tus operaciones.",
                contact: "Contacto",
                terms: "Terminos y Condiciones",
                policy: "Politicas de Privacidad",
                /* Sign In */
                forgotPassword: "¿Olvidaste tu contraseña?",
                email: "Correo electrónico",
                password: "Contraseña",
                logginIn: "Iniciando sesión",
                updatePassword: "Actualizar contraseña",
                updatePasswordMessage: "¿Olvidaste tu contraseña? No te preocupes, simplemente ingresa el correo electrónico asociado a tu cuenta y te enviaremos de inmediato las instrucciones para restablecerla. Sigue los pasos y estarás listo para acceder de nuevo a tu perfil de manera segura y rápida.",
                /* Sign Up */
                createProfile: "Crear perfil",
                name: "Nombre",
                lastname: "Apellido",
                termsAndConditions: "Terminos y Condiciones",
                privacyPolicies: "Politicas de Privacidad",
                legal1: "Acepto los",
                legal2: "de uso, además soy consciente de las",
                legal3: "al utilizar Onix y sus servicios.",
                birthDayOptional: "Fecha de nacimiento (opcional)",
                genderOptional: "Genero (opcional)",
                woman: "Mujer",
                man: "Hombre",
                other: "Otro",
                passwordHint: "Para mejorar la seguridad de tu cuenta, tu contraseña debe cumplir con los siguientes criterios:",
                hint1: "Contener al menos 6 caracteres.",
                hint2: "Incluir al menos una letra mayúscula.",
                hint3: "Incorporar al menos un número.",
                creating: "Creando perfil...",
                emptyNameOrLast: "El nombre y apellido no puede estar vacios",
                acceptTerms: "Debes aceptar los terminos y condiciones para continuar",
                passwordInvalid1: "La contraseña debe contener al menos 6 caracteres.",
                passwordInvalid2: "La contraseña debe contener al menos una letra mayúscula.",
                passwordInvalid3: "La contraseña debe contener al menos un número.",
                emailInUse: "El correo electrónico se encuentra en uso. Si tienes problemas para acceder en la sección de inicio de sesión puedes encontrar ayuda.",
                /* Home */
                today: "Hoy",
                myEvents: "Mis eventos",
                invitations: "Invitaciones",
                myCode: "Mi QR",
                editProfile: "Editar perfil",
                logout: "Cerrar sesión",
                doLogout: "Si, cerrar sesión",
                logoutMessage: "Estar por terminar tu sesión. ¿Quieres continuar?",
                /* Map Filter */
                mapTitle: "Buscador de eventos",
                centerMapButton: "Centar mapa en mi ubicación",
                mapLoadingMessage: "Cargando mapa...",
                locationPermissionsTitle: "Verifica que los permisos para la úbicación se encuentran hábilitados.",
                mapFilterDescription: "Descubre eventos cercanos al instante con Onix. ¡Deja que Onix te lleve a la acción! Utilizando tu ubicación, nuestra app encuentra rápidamente los eventos disponibles más cerca de ti. No te pierdas ninguna experiencia memorable: con Onix, tus mejores momentos están a solo un toque de distancia.",
                emptyFilterList: "Sin eventos en la zona",
                emptyFilterListDescription: "Lista de eventos vacia. Los eventos disponibles en Onix se muestran con un Pin de color",
                filterListTitle: "Eventos en el mapa",
                assist: "Asistir al evento",
                ticketReady: "¡Ticket listo!",
                ticketsNotAvailable: "Boletos no disponibles",
                alreadyInvited: "Ups! Parece que ya cuentas con un boleto o invitación para dicho evento, busca en tu lista de invitaciones.",
                eventCreator: "Creador del evento",
                /* My Events */
                myEventTitle: "Mis eventos",
                public: "Públicos",
                private: "Privados",
                createEvent: "Crear evento",
                activeEvent: "Evento activo",
                emptyList: "Lista vacía",
                startEvent: "Comenzar evento",
                edit: "Editar",
                invite: "Invitar",
                emptyPublicDescription: "La sección de eventos públicos no cuenta con elementos. Para crear un nuevo evento, solo preciona el botón de Crear Evento y descubre todo lo que Onix puede hacer por ti.",
                emptyPrivateDescription: "La sección de eventos privados no cuenta con elementos. Para crear un nuevo evento, solo preciona el botón de Crear Evento. Los eventos privados no pueden ser buscados dentro de Onix, solo personal e invitados exclusivos pueden asistir al evento. Descubre todo lo que puede hacer con Onix.",
                activeEventError: "Solo puedes tener un evento activo",
                cancelEventTitle: "Cancelar evento",
                cancelEventDescription: "¿Estas completamente seguro que deseas cancelar este evento? Invitaciones, boletos, entradas de personal, imagenes y toda la información sera eliminada.",
                cancelling: "Cancelando...",
                doCancel: "Si, Cancelar evento",
                /* Invitations */
                invitationsTitle: "Boletos e invitaciones",
                emptyInvitationsDescription: "Actualmente no cuentas con invitaciones o boletos.",
                openInvitation: "Abrir invitación",
                staff: "Personal",
                staffActiveEventError: "El evento aun no se encuentra activo",
                modalLocationTitle: "Ubicación del evento",
                acceptInvitation: "Aceptar invitación",
                declineInvitation: "Rechazar invitación",
                /* Profile */
                profileTitle: "Perfil",
                deleteAccount: "Eliminar cuenta",
                pictureTitle: "Fotografia",
                informationTitle: "Información",
                birthDay: "Fecha de nacimiento",
                gender: "Genero",
                updateProfile: "Actualizar perfil",
                updating: "Actualizando...",
                doSendEmail: "Si, enviar correo",
                doDeleteAccount: "Si, eliminar cuenta",
                updateProfileError: "Ups! Algo salio mal al intentar actualizar tu perfil. Intenta más tarde",
                deleteAccountDescription: "Estas a punto de eliminar tu cuenta de Onix. Eventos, invitaciones, boletos, información de usuario y cualquier otra información relacionada será eliminada permanentemente. ¿Deseas continuar?",
                updatePasswordDescription: "Enviaremos un correo a {{email}}. Dentro, encontraras información de como cambiar tu contraseña, además cerraremos tu sesión para que puedas acceder nuevamente.",
                /* QR Scanner */
                scannerTitle: "Lector de código QR",
                scannerDescription: "Coloca el código QR de la invitación para buscar a la persona",
                searching: "Buscando...",
                register: "Registrar",
                registering: "Registrando...",
                invalidCode: "Ups! Parece que el código no es valido",
                invalidInvitation: "Ups! La invitación es para otro evento",
                guestTitle: "Invitado",
                /* Active Event */
                activeEventTitle: "Evento activo",
                guestsTitle: "Invitados",
                scanInvitation: "Escanear Invitación",
                finishEvent: "Finalizar evento",
                endingEvent: "Finalizando...",
                endingModalDescription: "¿Estas seguro que deseas terminar este evento? invitaciones, accesos, imagenes y toda información sera borrada permanente mente.",
                /* Edit Event */
                editEventTitle: "Editar evento",
                eventNamePlaceholder: "Nombre del evento",
                eventDescriptionPlaceholder: "Descripción del evento",
                eventTypeTitle: "Tipo de evento",
                seekerTitle: "Buscador",
                eventDateTitle: "Fecha del evento",
                eventTimeTitle: "Hora del evento",
                eventImageTitle: "Imagen del evento",
                changeLocation: "Cambiar ubicación",
                updateEventTitle: "Actualizar evento",
                updatingEventTitle: "Actualizando evento...",
                dateAndTimeError: "La fecha y hora del evento solo aplica para fechas y horas presentes o futuras.",
                publicEventDescription: "Los eventos públicos, pueden ser vistos y buscados en la sección de Hoy, cualquier usuario dentro de Onix puede ver y asistir a tu evento.",
                privateEventDescription: "Los eventos privados, no pueden ser vistos y buscados por ningun usuario de Onix, solo personal e invitados exclusivos pueden asistir a tu evento.",
                unlimitedGuestsTitle: "Invitados y personal ilimitados",
                guestNumberTitle: "Número de invitados",
                unilimitedEventDescription: "Con nuestra modalidad de invitados y personal ilimitado, podrás crear experiencias inolvidables, esta función premiun está disponible para llevar tus eventos al siguiente nivel.",
                /* Staff Event */
                staffEventTitle: "Evento activo - Modo Staff",
                /* Search Guest */
                unlimitedReminder: "Recuerda que puedes enviar un máximo de {{maxGuests}} invitaciones, además de incluir a {{maxStaff}} personas como parte del personal (Staff) en tu evento.",
                withInvitationTitle: "Con invitación",
                sendInvitation: "Enviar invitación",
                includeAsStaff: "Incluir como personal",
                selected: "Seleccionados",
                remove: "Remover",
                add: "Agregar",
                searchForGuests: "Buscar invitados",
                searchPlaceholder: "¿A quien buscas? Puedes buscar por nombre, apellido o correo",
                noResults: "Sin resultados",
                searchHint: "Puedes buscar a la persona por su nombre, apellido o correo electronico dentro de Onix",
                maxGuestsError: "Solo puedes tener un maximo de {{number}} invitados en tu evento",
                maxStaffError: "Solo puedes tener un maximo de {{number}} miembros del personal",
                /* Event Location */
                searchLocationPlaceholder: "Dirección o nombre del lugar",
                placeNameTitle: "Nombre del lugar",
                addressTitle: "Dirección",
                mapLocationDescription: "El mapa te ayuda a buscar lugares cerca de ti o en cualquier parte que lo desees, puedes buscar por nombre o dirección, tambien puedes hacer click en cualquiera de los marcadores visibles en el mapa.",
                mapLocationHint: "Puedes buscar el lugar por nombre o dirección, además de hacer click en cualquiera de los marcadores del mapa, el nombre y la dirección del lugar seran actualizados automaticamente.",
                /* Event Information */
                eventInfoTitle: "Información del evento",
                dateAndTimeEmpty: "Selecciona la fecha y hora del evento",
                guestNumberPlaceholder: "Desde 1 hasta {{number}} invitados",
                unlimitedEventDescription: "Con nuestra modalidad de invitados y personal ilimitado, podrás crear experiencias inolvidables, esta función premiun está disponible para llevar tus eventos al siguiente nivel.",
                /* Event Description */
                eventImageDescription: "Personaliza tus eventos con Onix. Añade un toque único a cada invitación y boleto incorporando imágenes que hablen por sí mismas. Tus boletos e invitaciones no solo abrirán las puertas a tus eventos, sino que también capturarán la esencia de cada ocasión. ¡Haz que cada detalle cuente y deja una impresión memorable en tus invitados con invitaciones visualmente atractivas!",
                creatingEvent: "Creando evento...",
                /* Payment */
                fetchingStripeInfo: "Obteniendo información",
                successPaymentTitle: "¡Tu Pago se Completó Exitosamente!",
                successPaymentMessage: "¡Bienvenido a la experiencia Premium! Ahora tienes acceso ilimitado a personal, invitados y emisión de boletos, lo que te permitirá organizar eventos sin límites y con total comodidad. Aprovecha todas las ventajas exclusivas y comienza a planificar tu próximo evento con total libertad. ¡Estamos emocionados de ser parte de tu éxito!",
                /* Invitation */
                receivedInvitation: "Invitación recibida"
            },
        },
        en: {
            translation: {
                /* Common */
                appName: "Onix",
                emailFormatError: "The email must be in a valid format, please verify the data and try again.",
                emptyEmail: "Empty email",
                invalidCredentials: "Incorrect email or password, please verify the data and try again.",
                connectionError: "Oops! Something's wrong with the internet connection",
                generalError: "Oops! Something went wrong. Please try again later.",
                noSuchPageTitle: "Page not found",
                pageErrorDescription: "There appears to be an error with the page you are attempting to access.",
                goIndex: "Back to start",
                loading: "Hang on a minute...",
                geolocationNotAvailable: "Geolocation not available",
                minAgeErro: "The minimum age allowed is {{age}} years old",
                seeMore: "See more",
                search: "Search",
                image: "Image",
                error: "Error",
                send: "Send",
                accept: "Ok",
                cancel: "Cancel",
                next: "Next",
                date: "Date",
                time: "Time",
                no: "No",
                /* Main */
                signIn: "Login",
                signUp: "Create account",
                header1: "Your best option for digital events",
                description1: "Discover Onix, designed to create events in a way sustainable. Manage your guests, include staff and broadcast tickets and digital invitations, thus contributing to the care of the environment.",
                iosApp: "iOS free app",
                header2: "Goodbye to paper!",
                description2: "Still using paper for your events? With Onix, get a free mobile system for digital tickets, guest verification, and automatic invitation removal once the event is over. Join the green movement!",
                header3: "Manage your staff",
                description3: "Easily invite and manage staff at your events with Onix. Each member can verify invitations in real time using QR technology, reducing paper use and streamlining your operations.",
                contact: "Contact",
                terms: "Terms and Conditions",
                policy: "Privacy Policy",
                /* Sign In */
                forgotPassword: "Forgot your password?",
                email: "Email",
                password: "Password",
                logginIn: "Logging in",
                updatePassword: "Update password",
                updatePasswordMessage: "Forgot your password? Don't worry, just enter the email associated with your account and we will immediately send you instructions to reset it. Follow the steps and you will be ready to access your profile again safely and quickly.",
                /* Sign Up */
                createProfile: "Create profile",
                name: "Name",
                lastname: "Lastname",
                termsAndConditions: "Terms and Conditions",
                privacyPolicies: "Privacy Policies",
                legal1: "I accept the",
                legal2: "of use, and I am aware of the",
                legal3: "when using Onix and its services.",
                birthDayOptional: "Date of birth (optional)",
                genderOptional: "Gender (optional)",
                woman: "Woman",
                man: "Man",
                other: "Other",
                passwordHint: "To improve the security of your account, your password must meet the following criteria:",
                hint1: "Contain at least 6 characters.",
                hint2: "Include at least one capital letter.",
                hint3: "Include at least one number.",
                creating: "Creating profile...",
                emptyNameOrLast: "The name and lastname cannot be empty",
                acceptTerms: "You must accept the terms and conditions to continue",
                passwordInvalid1: "The password must contain at least 6 characters.",
                passwordInvalid2: "The password must contain at least one capital letter.",
                passwordInvalid3: "The password must contain at least one number.",
                emailInUse: "The email address is currently in use. If you have problems accessing in the login section you can find help.",
                /* Home */
                today: "Today",
                myEvents: "My events",
                invitations: "Invitations",
                myCode: "My Code",
                editProfile: "Edit profile",
                logout: "Logut",
                doLogout: "Yes, log out",
                logoutMessage: "Your session is about to end. Do you want to continue?",
                /* Map Filter */
                mapTitle: "Event finder",
                centerMapButton: "Center map on my location",
                mapLoadingMessage: "Loading map...",
                locationPermissionsTitle: "Verify that the permissions for the location are enabled.",
                mapFilterDescription: "Discover nearby events instantly with Onix. Let Onix take you into the action! Using your location, our app quickly finds available events closest to you. Don't miss out on any memorable experiences with Onix, your best moments are just a tap away.",
                emptyFilterList: "No events in the area",
                emptyFilterListDescription: "Empty event list. The available events in Onix are shown with a colored Pin",
                filterListTitle: "Events on the map",
                assist: "Attend the event",
                ticketReady: "Ticket ready!",
                ticketsNotAvailable: "Tickets not available",
                alreadyInvited: "Oops! It looks like you already have a ticket or invitation for that event, check your invitations",
                eventCreator: "Event creator",
                /* My Events */
                myEventTitle: "My events",
                public: "Public",
                private: "Private",
                createEvent: "Create event",
                activeEvent: "Active event",
                emptyList: "Empty list",
                startEvent: "Start event",
                edit: "Edit",
                invite: "Invite",
                emptyPublicDescription: "The public events section does not have any elements. To create a new event, just press the Create Event button and discover everything Onix can do for you.",
                emptyPrivateDescription: "The private events section does not have any items. To create a new event, just press the Create Event button. Private events cannot be searched within Onix, only staff and exclusive guests can attend the event. Discover all you can do with Onix.",
                activeEventError: "You can only have one active event",
                cancelEventTitle: "Cancel evento",
                cancelEventDescription: "Are you absolutely sure you want to cancel this event? Invitations, tickets, staff tickets, images and all the information will be deleted.",
                cancelling: "Cancelling...",
                doCancel: "Yes, Cancel event",
                /* Invitations */
                invitationsTitle: "Tickets and invitations",
                emptyInvitationsDescription: "You do not currently have any invitations or tickets.",
                openInvitation: "Open invitation",
                staff: "Staff",
                staffActiveEventError: "The event is not yet active",
                modalLocationTitle: "Event location",
                acceptInvitation: "Accept invitation",
                declineInvitation: "Decline invitation",
                /* Profile */
                profileTitle: "Profile",
                deleteAccount: "Delete account",
                pictureTitle: "Picture",
                informationTitle: "Information",
                birthDay: "Date of birth",
                gender: "Gender",
                updateProfile: "Update profile",
                updating: "Updating...",
                doSendEmail: "Yes, send email",
                doDeleteAccount: "Yes, delete account",
                updateProfileError: "Oops! Something went wrong while trying to update your profile. Please try again later.",
                deleteAccountDescription: "You are about to delete your Onix account. Events, invitations, tickets, user information and any other related information will be permanently deleted. Do you wish to continue?",
                updatePasswordDescription: "We will send an email to {{email}}. Inside, you will find information on how to change your password, and we will also close your session so you can log in again.",
                /* QR Scanner */
                scannerTitle: "QR code reader",
                scannerDescription: "Use the QR code on the invitation to search for the person",
                searching: "Searching...",
                register: "Register",
                registering: "Registering...",
                invalidCode: "Oops! It seems the code is not valid",
                invalidInvitation: "Oops! The invitation is for another event",
                guestTitle: "Guest",
                /* Active Event */
                activeEventTitle: "Active event",
                guestsTitle: "Guests",
                scanInvitation: "Scan Invitation",
                finishEvent: "Finish event",
                endingEvent: "Ending...",
                endingModalDescription: "Are you sure you want to end this event? Invitations, tickets, images and all information will be permanently deleted.",
                /* Edit Event */
                editEventTitle: "Edit event",
                eventNamePlaceholder: "Event name",
                eventDescriptionPlaceholder: "Event Description",
                eventTypeTitle: "Event type",
                seekerTitle: "Seeker",
                eventDateTitle: "Event date",
                eventTimeTitle: "Event time",
                eventImageTitle: "Event image",
                changeLocation: "Change location",
                updateEventTitle: "Update event",
                updatingEventTitle: "Updating event...",
                dateAndTimeError: "The event date and time only applies to current or future dates and times.",
                publicEventDescription: "Public events can be viewed and searched in the Today section, any user within Onix can view and attend your event.",
                privateEventDescription: "Private events cannot be viewed or searched by any Onix user, only staff and exclusive guests can attend your event.",
                unlimitedGuestsTitle: "Unlimited guests and staff",
                guestNumberTitle: "Number of guests",
                unilimitedEventDescription: "With our unlimited guests and staff option, you can create unforgettable experiences. This premium feature is available to take your events to the next level.",
                /* Staff Event */ 
                staffEventTitle: "Active event - Staff Mode",
                /* Search Guest */
                unlimitedReminder: "Please note that you can send a maximum of {{maxGuests}} invitations, plus include {{maxStaff}} people as staff at your event.",
                withInvitationTitle: "With invitation",
                sendInvitation: "Send invitation",
                includeAsStaff: "Include as staff",
                selected: "Selected",
                remove: "Remove",
                add: "Add",
                searchForGuests: "Search for guests",
                searchPlaceholder: "Who are you looking for? You can search by first name, last name or email",
                noResults: "No results",
                searchHint: "You can search for the person by their first name, last name or email within Onix",
                maxGuestsError: "You can only have a maximum of {{number}} guests at your event",
                maxStaffError: "You can only have a maximum of {{number}} staff members",
                /* Event Location */
                searchLocationPlaceholder: "Address or name of the place",
                placeNameTitle: "Place name",
                addressTitle: "Address",
                mapLocationDescription: "The map helps you search for places near you or anywhere you want, you can search by name or address, you can also click on any of the pins visible on the map.",
                mapLocationHint: "You can search for the place by name or address, and by clicking on any of the markers on the map, the name and address of the place will be updated automatically.",
                /* Event Information */
                eventInfoTitle: "Event Information",
                dateAndTimeEmpty: "Select the date and time of the event",
                guestNumberPlaceholder: "From 1 to {{number}} guests",
                unlimitedEventDescription: "With our unlimited guests and staff option, you can create unforgettable experiences. This premium feature is available to take your events to the next level.",
                /* Event Description */
                eventImageDescription: "Personalize your events with Onix. Add a unique touch to each invitation and ticket by incorporating images that speak for themselves. Your tickets and invitations will not only open the doors to your events, but will also capture the essence of each occasion. Make every detail count and leave a memorable impression on your guests with visually appealing invitations!",
                creatingEvent: "Creating event...",
                /* Payment */
                fetchingStripeInfo: "Fetching information",
                successPaymentTitle: "Payment Successfully Completed!",
                successPaymentMessage: "Welcome to the Premium experience! You now have unlimited access to staff, guests, and ticket issuance, giving you the freedom to organize your events without limits and with total convenience. Take advantage of all the exclusive benefits and start planning your next event with complete freedom. We're excited to be part of your success!",
                /* Invitation */
                receivedInvitation: "Invitation received"
            },
        }
    }
});